import React from 'react';

interface Props{
  direction: string;
  profile: string;
  text: React.ReactNode;
  opacity: string;
}

const CommentProfile:React.FC<Props> = (props) => {
  return (
    <div className={`comment-wrap ${props.direction}`} style={{opacity: props.opacity}}>
      <div className={`comment-container ${props.direction}`}>
        <img className='comment-profile' src={props.profile} alt='' />
        <div>{props.text}</div>
      </div>
    </div>
  );
};

export default CommentProfile;