import React from 'react';

interface Props{
  label: string;
  title: string;
  text: string;
};

const QAItem:React.FC<Props> = (props) => {
  return (
    <div className='qa-container'>
      <div className='qa-head'>
        <div className='qa-label'>{props.label}</div>
        <div className='qa-title'>{props.title}</div>
      </div>
      <div className='qa-text'>{props.text}</div>
    </div>
  );
};

export default QAItem;