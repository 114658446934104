import React from 'react';

const ApplicationComplete = () => {
  return (
    <div className='frame'>
      <div className='sec-complete'>
        <img className='logo24' src='/img/logo.png' alt='' />
        <div className='complete-title'>교육 신청이 완료되었습니다</div>
        <div className='complete-info'>
          <div className='title-infos'>
            <div className='title-info'>
              <div className='title-info-label'>주제 :</div>
              <div className='title-info-data'>글로벌 Top10 스위스 취리히 상품으로 세일즈 점프업 </div>
            </div>
            <div className='title-info'>
              <div className='title-info-label'>일시 :</div>
              <div className='title-info-data'>2024년 10월 25일 금요일 오후2시</div>
            </div>
            <div className='title-info'>
              <div className='title-info-label'>장소 :</div>
              <div className='title-info-data'>서울시 서초구 서초대로77길 17<br />블럭77타워 11층</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationComplete;