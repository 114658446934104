import './App.css';
import './styles/common.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './configs/routes';
import ApplicationForm from './pages/application_form';
import ApplicationComplete from './pages/application_complete';
import AlarmForm from './pages/alarm_form';
import { useEffect, useState } from 'react';
import initFirebase from './utils/init_firebase';

function App() {
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    console.log('1');
    initFirebase();

    const greet = localStorage.getItem('greet');
    if (greet === null) {
      localStorage.setItem('greet', '1');
      localStorage.setItem('greet_at', new Date().toLocaleString());
    } else {
      localStorage.setItem('greet', String(Number(greet) + 1));
    }

    const flag = localStorage.getItem('flag') ?? '';
    if (flag !== '') {
      setIsOver(true);
    }
  }, []);

  if (!isOver) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path={routes.default} element={<ApplicationForm />} />
          <Route path={routes.complete} element={<ApplicationComplete />} />
          <Route path={routes.over} element={<AlarmForm />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.default} element={<ApplicationComplete />} />
        <Route path={routes.over} element={<AlarmForm />} />
        <Route path={'*'} element={<ApplicationComplete />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
