import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyB2-y5DU3P4LQ-3MLMWcQPxhOfX8ymDA8k",
    authDomain: "goldenwayve-recruit.firebaseapp.com",
    projectId: "goldenwayve-recruit",
    storageBucket: "goldenwayve-recruit.appspot.com",
    messagingSenderId: "413564596799",
    appId: "1:413564596799:web:0081fc8a1e531fe451fe8a",
    measurementId: "G-G3LCJ3WV95"
  };
  
  // Initialize Firebase
  const apps = getApps();
  if (apps.length === 0) {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }
};

export default initFirebase;
