import React, { useEffect, useRef, useState } from 'react';
import Button from '../components/button';
import CommentProfile from '../components/comment';
import QAItem from '../components/qa_item';
import TextInput from '../components/input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFirestore, setDoc, doc } from 'firebase/firestore/lite';
import { getApp } from 'firebase/app';
import routes from '../configs/routes';

const ApplicationForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [agree, setAgree] = useState(false);
  const agreeToggle = () => {
    setAgree(!agree);
    setAgreeValid('');
  };
  const [agreeValid, setAgreeValid] = useState('');

  const formRef = useRef<HTMLDivElement | null>(null);
  const moveForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [mobile, setMobile] = useState('');
  const [mail, setMail] = useState('');
  const [team, setTeam] = useState<number | null>(null);

  const nameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameValid('');
  };
  const birthdayChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBirthday(e.target.value);
    setBirthdayValid('');
  };
  const mobileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setMobile(e.target.value);
    setMobileValid('');
  };
  const mailChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
    setMailValid('');
  };
  const selectTeam = (n: number) => {
    setTeam(n);
    setTeamValid('');
  };

  const [nameValid, setNameValid] = useState('');
  const [birthdayValid, setBirthdayValid] = useState('');
  const [mobileValid, setMobileValid] = useState('');
  const [mailValid, setMailValid] = useState('');
  const [teamValid, setTeamValid] = useState('');

  const isValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const birthPattern = /^\d{8}$/;
    const mobilePattern = /^\d{10,11}$/;
    if (
      name === '' || !birthPattern.test(birthday) || !mobilePattern.test(mobile) || !emailPattern.test(mail) || team === null || !agree
    ) {
      return false; // error
    } else return true;
  };

  const validAllCheck = async () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const birthPattern = /^\d{8}$/;
    const mobilePattern = /^\d{10,11}$/;

    if (isValid()) {
      const tId = searchParams.get('tId') ?? '-';

      const db = getFirestore(getApp());
      await setDoc(doc(db, 'recruit_kr', new Date().getTime().toString()), {
        tId,
        name,
        birthday,
        mobile,
        mail,
        team: team === 1 ? '전속사' : 'GA',
        created: new Date().toLocaleString(),
        greet: {
          no: localStorage.getItem('greet'),
          firstAccessTime: localStorage.getItem('greet_at'),
        },
      });

      localStorage.setItem('flag', 'edu-1');
      navigate(routes.complete);
    } else {
      if (name === '') {
        setNameValid('error');
      }
      if (!birthPattern.test(birthday)) {
        setBirthdayValid('error');
      }
      if (!mobilePattern.test(mobile)) {
        setMobileValid('error');
      }
      if (!emailPattern.test(mail)) {
        setMailValid('error');
      }
      if (team === null) {
        setTeamValid('error');
      }
      if (!agree) {
        setAgreeValid('error');
      }
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isVisible, setIsVisible] = useState(false); // div가 화면에 있는지 여부

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting); // 요소가 화면에 보이면 true, 아니면 false
      },
      { threshold: 0.1 } // 10%가 보일 때 트리거
    );

    if (formRef.current) {
      observer.observe(formRef.current); // div를 감시
    }

    return () => {
      if (formRef.current) {
        observer.unobserve(formRef.current); // 컴포넌트 언마운트 시 observer 해제
      }
    };
  }, []);

  return (
    <div className='frame'>
      <div className='sec20'>
        <img className='logo24' src='/img/logo.png' alt='' />
        <div className='title-wrap'>
          <div className='title-label'>1회차 골든웨이브 보험 교육</div>
          <div className='title-name'><span className='yellow'>글로벌 Top10</span><br />스위스 취리히<br />상품으로<br />세일즈 점프업!</div>
          <div className='title-infos'>
            <div className='title-info'>
              <div className='title-info-label'>일시 :</div>
              <div className='title-info-data'>2024년 10월 25일 금요일 오후2시</div>
            </div>
            <div className='title-info'>
              <div className='title-info-label'>장소 :</div>
              <div className='title-info-data'>서울시 서초구 서초대로77길 17<br />블럭77타워 11층</div>
            </div>
          </div>
        </div>
        <img className='title-sec-img' src='/img/zurich.png' alt='' />
      </div>
      <div className='sec0'>
        <div className='title28'>이런 분이라면<br />꼭! 신청하세요</div>
        <div className='comments-wrap'>
          <CommentProfile
            direction='forward'
            profile='/img/profile1.png'
            text={<span>고객의 <span className='underline white'>장기목적자금</span> 설계를 하고 싶은데 추천할 상품이 없어요.</span>}
            opacity='1'
          />
          <CommentProfile
            direction='reverse'
            profile='/img/profile2.png'
            text={<span>더이상 종신보험을 <span className='underline white'>저축 컨셉</span>으로 추천하고 싶지 않아요.</span>}
            opacity='.9'
          />
          <CommentProfile
            direction='forward'
            profile='/img/profile3.png'
            text={<span><span className='underline white'>7% 단리 상품</span>을 자신있게 소개할 수 없어요.(단리는 단리잖아요.)</span>}
            opacity='.8'
          />
          <CommentProfile
            direction='reverse'
            profile='/img/profile4.png'
            text={<span><span className='underline white'>연금 목적</span>으로 권유하고 싶은데 어떤 상품을 추천해야 할지 모르겠어요.</span>}
            opacity='.7'
          />
          <CommentProfile
            direction='forward'
            profile='/img/profile5.png'
            text={<span>자녀에게 <span className='underline white'>자산을 이전</span>해 줄 수 있는 좋은 상품이 없을까요?</span>}
            opacity='.6'
          />
          <CommentProfile
            direction='reverse'
            profile='/img/profile6.png'
            text={<span>자신있고 당당하게 <span className='underline white'>경쟁력있는 상품</span>으로 고객에게 다가서고 싶어요.</span>}
            opacity='.5'
          />
          <CommentProfile
            direction='forward'
            profile='/img/profile7.png'
            text={<span>다시 한번 예전처럼 열정적으로 일하고 싶은데 열정이 안생겨요.</span>}
            opacity='.4'
          />
        </div>
        <img className='dots' src='/img/dots.png' alt='' />
        <div className='text-group'>
          <div className='text-group-title'>하나라도 해당이 된다면<br /><span className='yellow'>주저말고 신청하세요.</span></div>
          <div className='text-group-text'>고객에게 최고의 플랜과 그에 맞는 상품을 추천하고 싶은 대한민국 모든 보험 설계사님들에게 최고의 기회가 될 것이라고 확신합니다.</div>
        </div>
      </div>
      <div className='sec-qa'>
        <div className='qa-sec-title'>Q&A</div>
        <div className='qas-wrap'>
          <QAItem
            label='Q1'
            title='해외금융 직구가 무엇인가요?'
            text='해외금융 직구는 우리나라에 진출하지 않은 외국 금융회사의 금융상품을 국내의 소비자가 직접 가입하는 것을 말합니다. 글로벌 선진 금융 상품을 직접 구입할 수 있는 직구 개념으로 생각하시면 됩니다.'
          />
          <QAItem
            label='Q2'
            title='상품 가입 시 법적인 문제는 없나요?'
            text='한국에 거주하고 있는 거주자는 누구나 해외금융사의 상품을 이메일, 우편, 팩스 등의 수단을 이용해 가입하거나 해외에 직접 방문해 가입할 수 있습니다.(보험업감독규정 제1-6조 외국보험회사와의 보험계약 체결방법)'
          />
          <QAItem
            label='Q3'
            title='우리나라 금감원의 보호를 받지 못하나요?'
            text='국내에 들어와 있지 않은 해외상품을 국내 금융감독기관이 관리하거나 보호하지 않는 것은 당연합니다. 해외금융 상품은 해당국가 감독기관의 보호를 받는 것이 맞습니다.'
          />
          <QAItem
            label='Q4'
            title='왜 사람들이 잘 모르나요?'
            text='금융소비자가 우편이나 이메일 등의 방법으로 해외금융상품을 가입하는 것은 허용되어 있지만 국내 보험설계사나 대리점들의 중개행위는 금지가 되어 있습니다. 또한 광고나 홍보 역시 자유롭지가 않은 이유로 국내 소비자들에게 정보가 매우 한정되어 있기 때문입니다. '
          />
          <QAItem
            label='Q5'
            title='해외상품이라 좀 불안하지 않나요?'
            text='가입자는 해당 해외금융회사의 웹사이트에 로그인하여 본인 계약을 항상 조회할 수 있으며, 국내 소비자에게 안내해주는 플랫폼 센터가 마련되어 있기 때문에 전혀 불안해 하지 않으셔도 됩니다.'
          />
          <QAItem
            label='Q6'
            title='홍콩 금융시장 향후 계속 괜찮을까요?'
            text='홍콩은 뉴욕, 런던에 이어 세계 3대 금융허브 중 하나로 금융관련 제도나 문화가 발달되어 개인의 사유재산을 지키고 늘리는데 최적화되어 있습니다. 또한 저율관세나 외환자유로 인해 기업활동에서도 매우 유리합니다. '
          />
        </div>
      </div>
      <div className='sec-form' ref={formRef}>
        <div className='form-title'>교육 참가 신청서</div>
        <TextInput type='text' label='이름' placeholder='이름 입력...' value={name} onChange={nameChange} valid={nameValid} validMsg='이름을 확인해 주세요' />
        <TextInput type='tel' label='생년월일' placeholder='생년월일 8자리 입력...' value={birthday} onChange={birthdayChange} valid={birthdayValid} validMsg='생년월일을 확인해 주세요' maxLength={8}/>
        <TextInput type='tel' label='휴대전화 번호' placeholder='휴대전화 번호 입력...' value={mobile} onChange={mobileChange} valid={mobileValid} validMsg='휴대전화 번호를 확인해 주세요' maxLength={11}/>
        <TextInput type='email' label='이메일' placeholder='이메일 입력...' value={mail} onChange={mailChange} valid={mailValid} validMsg='이메일을 확인해 주세요' />
        <div className='text-input-wrap'>
          <div className='input-label'>소속</div>
          <div className='radio-wrap'>
            <div className='radio-item' onClick={() => selectTeam(1)}>
              <div className={`radio-icon ${team === 1 ? 'active' : ''}`}><div className='radio-icon-inner' /></div>
              <div className='radio-name'>전속사</div>
            </div>
            <div className='radio-item' onClick={() => selectTeam(2)}>
              <div className={`radio-icon ${team === 2 ? 'active' : ''}`}><div className='radio-icon-inner' /></div>
              <div className='radio-name'>GA</div>
            </div>
          </div>
          {
            teamValid === 'error' ?
            <div className='input-error'>소속을 선택해 주세요</div> : null
          }
        </div>
        <div className='personal-info-wrap'>
          <div className='personal-info-text-wrap'>
            <div className='personal-info-text-title'>개인정보 수집 및 이용에 대한 동의</div>
            <div className='personal-info-text'>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>1.</div>
                <div className='personal-info-text-data'><span className='semi w80'>수집 및 이용 항목</span>: 이름, 생년월일, 휴대전화 번호, 이메일, 소속</div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>2.</div>
                <div className='personal-info-text-data'><span className='semi w80'>수집 및 이용 목적</span>: Golden Wayve Limited에서 실시하는 보험 <span className='underline'>교육 참가자 파악 및 교육 안내를 위하여 최소한의 범위 내에서 개인정보를 수집하고 있습니다.</span></div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>3.</div>
                <div className='personal-info-text-data'><span className='semi w80'>개인정보의 보유 및 이용 기간</span>: 교육 참가자의 개인정보 수집 및 이용에 관한 동의일로부터 <span className='underline'>1회차 교육 종료 시까지</span> 위 목적을 위하여 보유 및 이용하게 됩니다.</div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>4.</div>
                <div className='personal-info-text-data'><span className='semi w80'>동의를 거부할 권리 및 동의를 거부할 경우의 불이익</span>: 수집하는 개인정보는 교육 참가자 파악 및 교육 안내를 위해 필수적이므로, 위 사항에 동의하셔야만 교육 참가 신청을 할 수 있습니다.</div>
              </div>
            </div>
          </div>
          <div className={`personal-agree-btn ${agree ? 'active' : ''}`} onClick={() => agreeToggle()}>
            <img className='personal-agree-icon' src='/img/check.png' alt='' />
            <div className='personal-agree-text'>Golden Wayve Limited가 위와 같이 개인정보를 수집·이용 하는 것에 동의합니다.</div>
          </div>
            {
              agreeValid === 'error' ?
              <div className='input-error'>개인정보 수집 및 이용에 대한 내용에 동의하지 않으면 교육 참가 신청을 할 수 없습니다.</div> : null
            }
        </div>
        <Button
          onClick={validAllCheck}
          text='참가 신청'
          disabled={!isValid()}
        />
        <div className='footer'>
          <div className='footer-text'>contact@goldenwayve.com</div>
          <div className='footer-text'>Unit 706, 7/Floor, South Seas Centre, Tower 2, 75 Mody Road, Tsimshatsui, Kowloon, HK.</div>
          <div className='footer-text'>copyright 2024 Golden Wayve Limited</div>
        </div>
      </div>
      {
        isVisible ? null :
        <div className='bottom-btn-wrap'>
          <Button onClick={moveForm} text='교육 신청 바로가기' />
        </div>
      }
    </div>
  );
};

export default ApplicationForm;